import { useState } from 'react';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import {
  useHowItWorksExperimentApi,
  type HowItWorksProps as Props,
  type CardContent,
} from '@hungryroot/exp-157-homepage-redesign';
import {
  Heading,
  HeadingVariant,
  Paragraph,
  ParagraphVariant,
} from '@hungryroot/ramen';
import { homepageAnalytics } from '@hungryroot/segment';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { Accordion } from '../Accordion/Accordion';
import styles from './HowItWorks.module.css';

export function HowItWorks(props: Props) {
  const {
    tellUsAboutYourselfImage,
    getYourPersonalizenPlanImage,
    enjoyHealthyDeliveriesImage,
  } = props;
  const isDesktop = useMedia(BreakpointQueryLarge, false);
  const segmentEvents = homepageAnalytics();
  const defaultCardContent = [
    {
      id: 'tell',
      title: '1. Tell us about yourself',
      image: tellUsAboutYourselfImage,
      copy: `Share your preferences, goals and must-have eats, so we can recommend food you'll love.`,
    },
    {
      id: 'personalized',
      title: '2. See our recommendations',
      image: getYourPersonalizenPlanImage,
      copy: `We fill your cart with food we think you’ll love, then you edit to get exactly what you want.`,
    },
    {
      id: 'healthy',
      title: '3. Enjoy healthy deliveries',
      image: enjoyHealthyDeliveriesImage,
      copy: `Get tasty groceries, easy recipes, and essential supplements delivered to your door. Skip or cancel anytime.`,
    },
  ];

  const content = useHowItWorksExperimentApi(props, {
    heading: "Here's how it works:",
    cardContent: defaultCardContent,
  });

  const [active, setActive] = useState<CardContent['id']>('tell');

  if (content === undefined) {
    return;
  }

  const { variant } = content;
  const section =
    variant !== undefined && variant >= 1 ? 'SmartCart' : 'How it Works';

  return (
    <div className={styles.outer}>
      <section
        className={clsx(styles.container, {
          [styles['container-variant']]: variant !== undefined && variant >= 1,
        })}
      >
        <div className={styles['content-container']}>
          <div className={styles.header}>
            <Heading variant={HeadingVariant.HeroAlt}>
              {content.heading}
            </Heading>
            {content.subheading && (
              <Paragraph
                variant={ParagraphVariant.Lead}
                className={styles.subheading}
              >
                {content.subheading}
              </Paragraph>
            )}
          </div>

          <ul className={styles['accordion-list-container']}>
            {content.cardContent.map(({ id, title, image, copy }) => {
              const isExpanded = id === active;
              return (
                <li className={styles.item} key={id}>
                  <Accordion
                    isExpanded={isExpanded}
                    onToggle={({ expanded }) => {
                      if (expanded === true) {
                        setActive(id);

                        segmentEvents.track(section, title);
                      }
                    }}
                    buttonClassName={styles['accordion-button']}
                    className={styles['accordion-container']}
                    headingClassName={styles['accordion-title']}
                    bodyClassName={styles['accordion-body']}
                    title={
                      <Heading variant={HeadingVariant.H3}>{title}</Heading>
                    }
                  >
                    <figure className={styles.figure}>
                      {isDesktop === false && (
                        <div
                          className={`${styles.image} ${
                            styles[`mobile-image-${id}`]
                          }`}
                        >
                          <img
                            loading="lazy"
                            src={image.mobile.src}
                            height={image.mobile.height}
                            width={image.mobile.width}
                            alt=""
                          />
                        </div>
                      )}
                      <figcaption className={styles.figcaption}>
                        <Paragraph variant={ParagraphVariant.Lead}>
                          {copy}
                        </Paragraph>
                      </figcaption>
                    </figure>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>
        {isDesktop === true && (
          <div>
            {content.cardContent.map(({ id, image }) => {
              const isActive = active === id;
              const activeClassName =
                isActive === true
                  ? styles['desktop-image-active']
                  : styles['desktop-image'];
              return (
                <div
                  className={`${activeClassName} ${
                    styles[`image-desktop-${id}`]
                  }`}
                  key={id}
                >
                  <img
                    loading="lazy"
                    src={image.desktop.src}
                    height={image.desktop.height}
                    width={image.desktop.width}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
}
